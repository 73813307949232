// extracted by mini-css-extract-plugin
export var component = "tips-module--component--N9idC";
export var container = "tips-module--container--MbfH3";
export var footer = "tips-module--footer--enUSE";
export var image = "tips-module--image--D80Li";
export var imageHolder = "tips-module--imageHolder--nw7EU";
export var long = "tips-module--long--eLiSX";
export var midContainer = "tips-module--midContainer--HDyk7";
export var moreButtonHolder = "tips-module--moreButtonHolder--Z0ZZ+";
export var outerContainer = "tips-module--outerContainer--+b0sn";
export var short = "tips-module--short--xtYzR";
export var time = "tips-module--time--NAi+4";