import * as React from "react";
import { Helmet } from "react-helmet";
import { Header } from "../header/header";
import { Level } from "react-accessible-headings";
import { WhereToBuyBar } from "../where-to-buy-bar/where-to-buy-bar";
import { Tips } from "../tips/tips";
import Footer from "../footer/footer";
import { LayoutProps } from "./types";
import AppProvider, { AppContext } from "../../app-provider";
import classNames from "classnames";
import Sticky from "react-stickynode";
import * as styles from "./styles.module.scss";

const Layout: React.FC<LayoutProps> = ({
  children,
  hideWhereToBuy,
  hideTips,
  tipsConfig,
  seoConfig,
  stickyHeader,
  additionalComponentsToRender,
}: LayoutProps) => {
  const tipLimitByPriority = tipsConfig?.limitByPriority ?? 4;
  const tipOrderByPriority = tipsConfig?.orderByPriority ?? true;

  const title = seoConfig?.title ?? "Öksürük Şurubu & Ağız ve Boğaz Spreyi";
  const description =
    seoConfig?.description ??
    "Öksürüğünü rahatlat! Bisolnatur şurup ve Bisolduo ailesinden öksürüğün için etkili ve uygun çözümü seç.";
  return (
    <AppProvider>
      <AppContext.Consumer>
        {(context) => (
          <Helmet
            titleTemplate="%s"
            defaultTitle="Öksürük Şurubu & Ağız ve Boğaz Spreyi | Bisolnatur & Bisolduo"
          >
            <html lang="tr" />
            <meta charSet="utf-8" />
            <title itemProp="name" lang="tr">
              {title +
                (!seoConfig?.noDefaultTitle ? " | Bisolnatur & Bisolduo" : "")}
            </title>
            <meta name="description" content={description} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="format-detection" content="telephone=no" />
            <meta property="og:title" content={title} />
            <meta property="og:type" content="website" />
            <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
            <meta
              name="facebook-domain-verification"
              content="lmd68u7ykta9t6m0vlqe98uigpqhgb"
            />
            <meta
              name="google-site-verification"
              content="NHi_93EGwfisQMvX4lM26p8cunP5e9zjkowAJew_1DE"
            />
            <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="bc9ab095-1b81-48ef-aba8-c8f8d8133866" ></script>
            <script type="text/javascript">
              {` function OptanonWrapper() { } `}
            </script>
            <body
              className={classNames(
                context.data.isMenuActive ? "menu-active" : "",
                stickyHeader ? "isFixed" : ""
              )}
            />
          </Helmet>
        )}
      </AppContext.Consumer>
      <div className={styles.content}>
        <Sticky enabled={stickyHeader} innerZ="6" enableTransforms={false}>
          <Header stickyHeader={stickyHeader} />
        </Sticky>
        <main>
          {children}
          <div
            className={classNames("bottomBoundary", styles.bottomBoundary)}
          />
          {!hideWhereToBuy && (
            <Level>
              <WhereToBuyBar />
            </Level>
          )}
          {!hideTips && (
            <Tips
              limitByPriority={tipLimitByPriority}
              orderByPriority={tipOrderByPriority}
            />
          )}
          {additionalComponentsToRender ?? ""}
        </main>
        <Footer />
      </div>
    </AppProvider>
  );
};

export default Layout;
