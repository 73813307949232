import * as React from "react";
import * as whereToBuyBarStyles from "./where-to-buy-bar.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { graphql, Link, StaticQuery } from "gatsby";
import {
  WhereToBuyBar as WhereToBuyBarType,
  WhereToBuyBarProps,
  WhereToBuyBarWrapper,
} from "./types";
import Container from "../container/container";
import classNames from "classnames";
import * as MenuStyles from "../header/menu/menu.module.scss";
import * as productBarStyles from "../product-bar/product-bar.module.scss";
import chevronRight from "../../images/chevron_right_black_24dp.svg";
import Heading from "../basic-components/heading/heading";
import Button from "../basic-components/button/button";
import { ButtonTypesEnum } from "../basic-components/button/types";
import { GatsbyImage } from "gatsby-plugin-image";

const WhereToBuyBarComp: React.FC<WhereToBuyBarProps> = ({
  item,
}: WhereToBuyBarProps) => {
  const map = (path: string) => {
    window.open(path, "_blank");
  }

  if (!item) return null;
  const {
    link: { path, text },
    title,
    description,
    image: {
      childImageSharp: { gatsbyImageData },
    },
  } = item;
  return (
    <Container
      fullWidth
      className={classNames("bg-blue", whereToBuyBarStyles.outerContainer)}
    >
      <GatsbyImage
        className={whereToBuyBarStyles.bgImg}
        loading="lazy"
        image={gatsbyImageData}
        alt={title + " harita görseli"}
      />
      <Container className={whereToBuyBarStyles.midContainer}>
        <div className={whereToBuyBarStyles.descriptionHolder}>
          <Heading className="medium">{title}</Heading>
          <p>{description}</p>
        </div>
        <div className={whereToBuyBarStyles.buttonHolder}>
          <Link to={path} target={"_blank"}>
            <b>{text}</b>
            <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"></path><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></span>
          </Link>
        </div>
      </Container>
    </Container>
  );
};

export const WhereToBuyBar: WhereToBuyBarWrapper = (props) => (
  <StaticQuery
    query={graphql`
      query WhereToBuyQuery {
        allWhereToBuyJson {
          nodes {
            title
            description
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  breakpoints: [360, 768, 1200, 2200]
                  placeholder: NONE
                )
              }
            }
            link {
              path
              text
            }
          }
        }
      }
    `}
    render={(data) => (
      <WhereToBuyBarComp {...props} item={data.allWhereToBuyJson.nodes[0]} />
    )}
  />
);
