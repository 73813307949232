import * as React from "react";
import * as MenuStyles from "./menu.module.scss";
import chevronRight from "./../../../images/chevron_right_black_24dp.svg";
import { Link } from "gatsby";
import classNames from "classnames";
import { MenuItemProps } from "./types";

export const MenuItem: React.FC<MenuItemProps> = ({
  item,
  isRoot,
  activeSubMenu,
}: MenuItemProps) => (
  <Link
    to={item?.link?.path ? item?.link?.path : "#"}
    activeClassName="orange"
    className={classNames(
      item.className || item.link?.className,
      isRoot && activeSubMenu !== 0 ? MenuStyles.hide : "",
      "medium",
      "blue"
    )}
  >
    {item.title}
    {(!item?.link || !item?.link?.path) && (
      <span className={MenuStyles.subMenuChevron}>
        <img src={chevronRight} alt="Sağ ok" />
      </span>
    )}
    <hr className={classNames("line", MenuStyles.line, "bg-orange")} />
  </Link>
);
