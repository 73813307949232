import * as React from "react";
import * as tipsStyles from "./tips.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "gatsby";
import { TipProps } from "./types";
import timer from "../../images/timer_black_24dp.svg";
import Heading from "../basic-components/heading/heading";
import classNames from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import { Level } from "react-accessible-headings";

const Tip: React.FC<TipProps> = ({ item }: TipProps) => {
  const {
    link: { path, text },
    title,
    description,
    timeToRead,
    image: {
      childImageSharp: { gatsbyImageData },
    },
  } = item;

  return (
    <Link to={path}>
      <div className={tipsStyles.component}>
        <div className={tipsStyles.imageHolder}>
          <GatsbyImage
            className={tipsStyles.image}
            loading="lazy"
            image={gatsbyImageData}
            alt={title + " görseli"}
          />
        </div>
        <Level>
          <Heading className="extra-bold blue">{title}</Heading>
        </Level>
        <p>{description}</p>
        <div
          className={classNames(
            "flexbox",
            "bg-orange",
            "lighten2",
            tipsStyles.footer
          )}
        >
          <hr className={classNames("line", "bg-orange", "lighten1")} />
          <p className={classNames("bold", "blue")}>DEVAMINI OKUYUN</p>
          <div className={tipsStyles.time}>
            <img width="24" height="24" src={timer} alt="Okuma süresi ikon" />
            <span className={tipsStyles.short}>{timeToRead} dk.</span>
            <span className={tipsStyles.long}>{timeToRead} dakikalık okuma</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Tip;
