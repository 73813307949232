import * as React from "react";
import * as MenuStyles from "./menu.module.scss";
import chevronLeft from "./../../../images/chevron_left_white_24dp.svg";
import { graphql, StaticQuery } from "gatsby";
import { useContext, useLayoutEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import {
  MenuItem as MenuItemType,
  MenuProps,
  MenuWrapper,
  MouseEnterFn,
  MouseOutFn,
} from "./types";
import { MenuItem } from "./menu-item";
import { AppContext, AppContextType } from "../../../app-provider";

const MenuComp: React.FC<MenuProps> = ({ items }: MenuProps) => {
  const MenuItems: MenuItemType[] = items ?? [];
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(0);
  const windowWidth = useWindowWidth();
  const radioInput = useRef<HTMLInputElement>(null);
  const appContext = useContext(AppContext);

  useLayoutEffect(() => {
    if (windowWidth >= 1200 && isMenuOpen) {
      setIsMenuOpen(false);
      if (appContext?.updateState) {
        appContext.updateState("isMenuActive", false);
      }
      setActiveSubMenu(0);
      if (
        radioInput !== null &&
        radioInput.current &&
        radioInput.current.checked
      ) {
        radioInput.current.checked = false;
      }
    }
  }, [windowWidth]);

  const toggleMenu = (context: AppContextType) => () => {
    setIsMenuOpen(!isMenuOpen);
    if (context?.updateState) {
      context?.updateState("isMenuActive", !isMenuOpen);
    }
    if (!isMenuOpen) {
      setActiveSubMenu(0);
    }
  };

  const onMouseEnter: MouseEnterFn =
    (item, isRoot, isClick = false) =>
      () => {
        if ((!isClick && windowWidth < 1200) || item?.link?.path || !isRoot)
          return;
        setActiveSubMenu(parseInt(item.id));
      };

  const onMouseOut: MouseOutFn = (item, isRoot) => () => {
    if (windowWidth < 1200 || item?.link?.path || !isRoot) return;
    setActiveSubMenu(0);
  };

  return (
    <AppContext.Consumer>
      {(context) => (
        <React.Fragment>
          <input
            className={MenuStyles.menuBtn}
            type="checkbox"
            id="menu-btn"
            ref={radioInput}
          />
          <label
            className={
              MenuStyles.menuIcon + " " + (isMenuOpen && MenuStyles.isMenuOpen)
            }
            htmlFor="menu-btn"
            onClick={toggleMenu(context)}
          >
            <span className={classNames(MenuStyles.navicon, "bg-blue")} />
            <span className={classNames(MenuStyles.navText, "bold", "blue")}>
              {isMenuOpen ? "KAPAT" : "MENU"}
            </span>
          </label>
          <nav className={MenuStyles.nav}>
            {activeSubMenu !== 0 && (
              <a
                key="100"
                href="#"
                className={classNames(MenuStyles.backBtn, "white", "bg-orange")}
                onClick={() => setActiveSubMenu(0)}
              >
                <span className={MenuStyles.backBtnChevron}>
                  <img src={chevronLeft} alt="sol ok" width="24" height="24" />
                </span>
                GERİ
              </a>
            )}
            {MenuItems.map((node, i) =>
              node.items.length > 0 ? (
                <div
                  className={MenuStyles.rootDiv}
                  key={i * 1000}
                  onClick={onMouseEnter(node, true, true)}
                  onMouseEnter={onMouseEnter(node, true)}
                  onMouseLeave={onMouseOut(node, true)}
                >
                  <MenuItem
                    item={node}
                    activeSubMenu={activeSubMenu}
                    isRoot={true}
                  />
                  <div
                    className={classNames(
                      MenuStyles.subMenuHolder,
                      parseInt(node.id) !== activeSubMenu
                        ? MenuStyles.hide
                        : undefined,
                      node.items.length > 1 && MenuStyles.multi
                    )}
                  >
                    {node.items.map((item) => (
                      <div key={item.id}>
                        {item?.image && (
                          <img
                            src={item.image.publicURL}
                            alt={item.title}
                            width="113"
                            height="20"
                          />
                        )}
                        {item.items.map((subItem) => (
                          <MenuItem
                            key={subItem.id}
                            item={subItem}
                            activeSubMenu={activeSubMenu}
                            isRoot={false}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <MenuItem
                  key={i * 1000}
                  item={node}
                  activeSubMenu={activeSubMenu}
                  isRoot={true}
                />
              )
            )}
          </nav>
        </React.Fragment>
      )}
    </AppContext.Consumer>
  );
};

export const Menu: MenuWrapper = (props: MenuProps) => (
  <StaticQuery
    query={graphql`
      query MenuQuery {
        allMenuJson {
          nodes {
            items {
              id
              inner
              image {
                publicURL
              }
              items {
                image {
                  publicURL
                }
                className
                id
                link {
                  className
                  inner
                  path
                }
                title
              }
            }
            link {
              inner
              path
            }
            title
            id
            className
          }
        }
      }
    `}
    render={(data) => <MenuComp {...props} items={data.allMenuJson.nodes} />}
  />
);
