import * as React from "react";
import { H, useLevel } from "react-accessible-headings";
import classNames from "classnames";
import { HeadingProps } from "./types";

const Heading: React.FC<HeadingProps> = (props: HeadingProps) => {
  const level = useLevel();
  return (
    <H
      {...props}
      className={classNames(
        props.className !== "purple"
          ? [3, 5].includes(level)
            ? "orange"
            : "blue"
          : "",
        "bold",
        props.className
      )}
    >
      {props.children}
    </H>
  );
};

export default Heading;
