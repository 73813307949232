import * as React from "react";
import { useCallback, useEffect, useState } from "react";

interface UseScrolltateStype {
  lastScrollTop: number;
  bodyOffset: DOMRect;
  scrollY: number;
  scrollX: number;
  scrollDirection: string;
}
export const useScroll = () => {
  const [state, setState] = useState<UseScrolltateStype>({
    lastScrollTop: 0,
    bodyOffset: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      toJSON: () => ({ x: 0 }),
    },
    scrollY: 0,
    scrollX: 0,
    scrollDirection: "",
  });

  const handleScrollEvent = useCallback(() => {
    setState((prevState: UseScrolltateStype) => {
      const prevLastScrollTop = prevState.lastScrollTop;
      const bodyOffset = document.body.getBoundingClientRect();

      return {
        bodyOffset: bodyOffset,
        scrollY: -bodyOffset.top,
        scrollX: bodyOffset.left,
        scrollDirection: prevLastScrollTop > -bodyOffset.top ? "down" : "up",
        lastScrollTop: -bodyOffset.top,
      };
    });
  }, []);

  useEffect(() => {
    setState({
      lastScrollTop: 0,
      bodyOffset: document.body.getBoundingClientRect(),
      scrollY: document.body.getBoundingClientRect().top,
      scrollX: document.body.getBoundingClientRect().left,
      scrollDirection: "",
    });
  }, []);
  useEffect(() => {
    const scrollListener = () => {
      handleScrollEvent();
    };
    window.addEventListener("scroll", scrollListener);

    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, [handleScrollEvent]);

  return {
    scrollY: state.scrollY,
    scrollX: state.scrollX,
    scrollDirection: state.scrollDirection,
  };
};

export default useScroll;
