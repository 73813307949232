import * as React from "react";
import {useContext} from "react";
import * as HeaderStyles from "./header.module.scss";
import logo from "../../images/Bisolvon-logo.png";
import {Link} from "gatsby";
import Container from "../container/container";
import {Menu} from "./menu/menu";
import classNames from "classnames";
import logo3x from "../../images/Sanofi_logo_2022_black_font-01.svg";
import {HeaderProps} from "./types";
import {AppContext} from "../../app-provider";

export const Header: React.FC<HeaderProps> = ({
                                                stickyHeader,
                                              }: HeaderProps) => {
  const appContext = useContext(AppContext);
  const scrollY = stickyHeader ? appContext.scrollY : 0;

  let height = "auto";
  if (stickyHeader && scrollY > 0) {
    if (scrollY <= 40) {
      height = 80 - scrollY + "px";
    } else {
      height = "40px";
    }
  }
  return (
      <header
          className={classNames("header", "bg-white", HeaderStyles.header)}
          style={{
            overflow: stickyHeader && scrollY > 0 ? "hidden" : "visible",
            height: height,
          }}
      >
        <Container className={HeaderStyles.headerTop}>
          <a
              target="_blank"
              rel="noopener noreferrer external"
              className={HeaderStyles.logoLink}
              href="https://www.sanofi.com.tr/"
              aria-label="Sanofi Web Sitesi"
          >
            <img
                src={logo3x}
                alt="Sanofi logo"
                width="49"
                height="20"
            />
          </a>
        </Container>
        <hr className={classNames("line", HeaderStyles.line, "bg-orange")}/>
        <Container>
          <div className={classNames(HeaderStyles.wrapper, "flexbox")}>
            <Link
                className={HeaderStyles.logo}
                rel="noopener noreferrer"
                to="/"
                aria-label="Bisolnatur Anasayfa"
            >
              <img src={logo} alt="Bisolnatur logo" width="107" height="20"/>
            </Link>
            <Menu/>
          </div>
        </Container>
        <hr
            className={classNames("line", HeaderStyles.line, "bottom", "bg-orange")}
        />
      </header>
  );
};
