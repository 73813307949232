import * as React from "react";
import { ButtonProps } from "./types";
import { Link } from "gatsby";
import * as ButtonStyles from "./button.module.scss";
import chevronRight from "../../../images/chevron_right_black_24dp.svg";
import classNames from "classnames";

const Button: React.FC<ButtonProps> = ({
  to,
  children,
  type,
  className,
  isForm,
}: ButtonProps) => {
  if (!isForm) {
    return (
      <Link
        to={to}
        className={classNames(
          ButtonStyles.button,
          ButtonStyles[type || "navy"],
          "bold",
          className
        )}
      >
        {children}
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
          </svg>
        </span>
      </Link>
    );
  }
  return (
    <button
      className={classNames(
        ButtonStyles.button,
        ButtonStyles[type || "navy"],
        "bold",
        className
      )}
    >
      {children}
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
        </svg>
      </span>
    </button>
  );
};

export default Button;
