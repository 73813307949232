import * as React from "react";
import * as tipsStyles from "./tips.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { graphql, Link, StaticQuery } from "gatsby";
import { Tip as TipType, TipsProps, TipsWrapper } from "./types";
import Tip from "./tip";
import Container from "../container/container";
import Heading from "../basic-components/heading/heading";
import { Level } from "react-accessible-headings";
import Button from "../basic-components/button/button";
import { ButtonTypesEnum } from "../basic-components/button/types";

const TipsComp: React.FC<TipsProps> = ({
  item,
  limitByPriority,
  limitByLength,
  orderByPriority,
  orderByOrder,
  noMoreButton,
}: TipsProps) => {
  if (!item) return null;

  let items: TipType[] | undefined = item?.items;
  if (!items) return null;

  if (limitByPriority) {
    items = items.filter((tip: TipType) => tip.priority < limitByPriority);
  }
  if (orderByPriority) {
    items = items.sort((a, b) => (a.priority > b.priority ? 1 : -1));
  }
  if (orderByOrder) {
    items = items.sort((a, b) => (a.order > b.order ? 1 : -1));
  }
  if (limitByLength) {
    items = items.slice(0, limitByLength);
  }
  return (
    <div className={tipsStyles.outerContainer}>
      <Container className={tipsStyles.midContainer}>
        <Level>
          <Heading>{item.title}</Heading>
          <div className={tipsStyles.container}>
            {items.map((node) => (
              <Tip key={node.id} item={node} />
            ))}
          </div>
        </Level>
        <div className={tipsStyles.moreButtonHolder}>
          {!noMoreButton && (
            <Button type={ButtonTypesEnum.navyBorder} to="/oksuruk/">
              TÜM İPUÇLARINI GÖRÜN
            </Button>
          )}
        </div>
      </Container>
    </div>
  );
};

export const Tips: TipsWrapper = (props) => (
  <StaticQuery
    query={graphql`
      query TipQuery {
        allTipsJson {
          nodes {
            id
            items {
              description
              id
              link {
                path
                text
              }
              image {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    breakpoints: [360, 768]
                    placeholder: NONE
                  )
                }
              }
              order
              priority
              title
              timeToRead
            }
            title
          }
        }
      }
    `}
    render={(data) => <TipsComp {...props} item={data.allTipsJson.nodes[0]} />}
  />
);
