import * as React from "react";
import { createContext, useState } from "react";
import { Crumb } from "./components/breadcrumb/types";
import useScroll from "./hooks/useScroll";

const routes = {
  home: {
    path: "/",
    title: "Anasayfa",
  },
  urunler: {
    path: "/urunler/",
    title: "Ürünler",
  },
  bisolduo: {
    path: "/urunler/bisolduo/",
    title: "Bisolduo",
  },
  bisolnatur: {
    path: "/urunler/bisolnatur/",
    title: "Bisolnatur",
  },
  "bisolduo-surup": {
    path: "/urunler/bisolduo-surup/",
    title: "Bisolduo Şurup",
  },
  "bisolduo-sprey": {
    path: "/urunler/bisolduo-sprey/",
    title: "Bisolduo Sprey",
  },
  "bisolviral-sprey":{
    path: "/urunler/bisolviral-sprey/",
    title: "Bisolviral Sprey",
  },
  "merak-edilenler": {
    path: "/urunler/$r/merak-edilenler/",
    title: "Merak Edilenler",
  },
  "nasil-etki-eder": {
    path: "/urunler/$r/nasil-etki-eder/",
    title: "Nasıl Etki Eder",
  },
  hakkimizda: {
    path: "/hakkimizda/",
    title: "Hakkımızda",
  },
  fiyat: {
    path: "/urunler/$r/fiyat/",
    title: "Fiyat",
  },
  "nereden-satin-alabilirim": {
    path: "/nereden-satin-alabilirim/",
    title: "Nereden Satın Alabilirim?",
  },
  "bize-ulasin": {
    path: "/bize-ulasin/",
    title: "Bize Ulaşın",
  },
  "cerez-politikasi": {
    path: "/cerez-politikasi/",
    title: "Çerez Politikası",
  },
  "gizlilik-politikasi-ve-kvkk-aydinlatma-metni": {
    path: "/gizlilik-politikasi-ve-kvkk-aydinlatma-metni/",
    title: "Gizlilik Politikası ve KVKK Aydınlatma Metni",
  },
  "yasal-uyari-ve-kullanim-kosullari": {
    path: "/yasal-uyari-ve-kullanim-kosullari/",
    title: "Yasal Uyarı ve Kullanım Koşulları",
  },
  oksuruk: {
    path: "/oksuruk/",
    title: "Öksürük",
  },
  "bisolnatur-kullanicilari-anlatiyor": {
    path: "/bisolnatur-kullanicilari-anlatiyor/",
    title: "Bisolnatur Kullanıcıları Anlatıyor",
  },
  "oksuruge-ne-iyi-gelir-nasil-gecer": {
    path: "/oksuruge-ne-iyi-gelir-nasil-gecer/",
    title: "Öksürüğe Ne İyi Gelir? - Nasıl Geçer?",
  },
  "kuru-oksuruk-nasil-gecer": {
    path: "/kuru-oksuruk-nasil-gecer/",
    title: "Kuru Öksürük Nasıl Geçer?",
  },
  "kuru-oksuruk-nedir": {
    path: "/kuru-oksuruk-nedir/",
    title: "Kuru Öksürük Nedir?",
  },
  "balgamli-oksuruk": {
    path: "/balgamli-oksuruk/",
    title: "Balgamlı Öksürük",
  },
  "oksuruk-nedir": {
    path: "/oksuruk/oksuruk-nedir/",
    title: "Öksürük Nedir?",
  },
  "cocuklarda-oksuruge-ne-iyi-gelir": {
    path: "/oksuruk/cocuklarda-oksuruge-ne-iyi-gelir/",
    title: "Çocuklarda Öksürüğe Ne İyi Gelir?",
  },
  "cocuklarda-bogaz-agrisi-nasil-gecer": {
    path: "/oksuruk/cocuklarda-bogaz-agrisi-nasil-gecer/",
    title: "Çocuklarda Boğaz Ağrısı Nasıl Geçer?",
  },
  "bogaz-agrisi-nedenleri-nelerdir": {
    path: "/oksuruk/bogaz-agrisi-nedenleri-nelerdir/",
    title: "Boğaz Ağrısı Nedenleri Nelerdir?",
  },
  "bogaz-agrisi-nedir-nasil-gecer": {
    path: "/oksuruk/bogaz-agrisi-nedir-nasil-gecer/",
    title: "Boğaz Ağrısı Nedir, Nasıl Geçer?",
  },
  "ses-kisikligi-nedenleri-nelerdir-nasil-gecer": {
    path: "/oksuruk/ses-kisikligi-nedenleri-nelerdir-nasil-gecer/",
    title: "Ses Kısıklığı Nedenleri Nelerdir, Nasıl Geçer?",
  },
  "alerjik-oksuruk-nedir-ne-iyi-gelir": {
    path: "/oksuruk/alerjik-oksuruk-nedir-ne-iyi-gelir/",
    title: "Alerjik Öksürük Nedir Ne İyi Gelir?",
  },
  "balgam-nasil-atilir": {
    path: "/oksuruk/balgam-nasil-atilir/",
    title: "Balgam Nasıl Atılır?",
  },
  "balgam-nedir-neden-olur": {
    path: "/oksuruk/balgam-nedir-neden-olur/",
    title: "Balgam Nedir, Neden Olur?",
  },
  "bogaz-tahrisi-belirtileri-nelerdir-nasil-gecer": {
    path: "/oksuruk/bogaz-tahrisi-belirtileri-nelerdir-nasil-gecer/",
    title: "Boğaz Tahrişi Belirtileri Nelerdir, Nasıl Geçer?",
  },
  "hamilelikte-oksuruk-nasil-gecer": {
    path: "/oksuruk/hamilelikte-oksuruk-nasil-gecer/",
    title: "Hamilelikte Öksürük Nasıl Geçer?",
  },
  "kronik-oksuruk-nedir-nasil-gecer": {
    path: "/oksuruk/kronik-oksuruk-nedir-nasil-gecer/",
    title: "Kronik Öksürük Nedir, Nasıl Geçer?",
  },
  "soguk-alginligi-nedir-belirtileri-nelerdir": {
    path: "/oksuruk/soguk-alginligi-nedir-belirtileri-nelerdir/",
    title: "Soğuk Algınlığı Nedir, Belirtileri Nelerdir?",
  },
};

export type AppContextType = {
  data: StateType;
  routes: {
    [key: string]: Crumb;
  };
  scrollY: number;
  updateState?: (key: string, value: any) => void;
};

type StateType = {
  isMenuActive?: boolean;
  [key: string]: any;
};

export const AppContext = createContext<AppContextType>({
  data: {},
  routes,
  scrollY: 0,
});

const AppProvider: React.FC = (props) => {
  const [state, setState] = useState<StateType>({
    isMenuActive: false,
  });
  const { scrollY } = useScroll();

  return (
    <AppContext.Provider
      value={{
        data: state,
        routes,
        scrollY,
        updateState: (key: string, value: any) => {
          const newState = { ...state };
          newState[key] = value;
          setState(newState);
        },
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppProvider;
