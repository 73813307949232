import * as React from "react";
import * as footerStyles from "./footer.module.scss";
import logo3x from "./../../images/Sanofi_logo_2022_white_font-01.svg";
import Container from "../container/container";
import {Link} from "gatsby";

const Footer: React.FC = () => (
    <footer>
      <Container fullWidth className="bg-blue">
        <Container className={footerStyles.container}>
          <div className={footerStyles.linkHolder}>
            <Link className="orange lighten2" to={"/cerez-politikasi/"}>
              Çerez Politikası
            </Link>
            <Link
                className="orange lighten2"
                to={"/gizlilik-politikasi-ve-kvkk-aydinlatma-metni/"}
            >
              Gizlilik Politikası ve KVKK Aydınlatma Metni
            </Link>
            <Link
                className="orange lighten2"
                to={"/yasal-uyari-ve-kullanim-kosullari/"}
            >
              Yasal Uyarı ve Kullanım Koşulları
            </Link>
          </div>
          <a
              target="_blank"
              rel="noopener noreferrer external"
              className={footerStyles.logoLink}
              href="https://www.sanofi.com.tr/"
              aria-label="Sanofi Web Sitesi"
          >
            <img
                src={logo3x}
                alt="Sanofi logo"
                width="140"
                height="30"
            />
          </a>
        </Container>
      </Container>
    </footer>
);

export default Footer;
