import React from "react";

export enum ButtonTypesEnum {
  navy = "navy",
  navyBorder = "navyBorder",
  orangeBorder = "orangeBorder",
}
export type ButtonProps = React.HTMLAttributes<HTMLDivElement> & {
  children: undefined | JSX.Element[] | JSX.Element | string;
  to: string;
  type?: ButtonTypesEnum;
  isForm?: boolean;
};
