import * as React from "react";
import * as containerStyles from "./container.module.scss";
import { ContainerProps } from "./types";
import classNames from "classnames";

const Container: React.FC<ContainerProps> = ({
  children,
  className,
  fullWidth,
}: ContainerProps) => (
  <div className={classNames(containerStyles.container, className)}>
    <div
      className={classNames(
        containerStyles.content,
        fullWidth ? containerStyles.fullWidth : undefined
      )}
    >
      {children}
    </div>
  </div>
);

export default Container;
